import Row from "../../../Flexbox/Row";
import "./ContentPreviewDefault.scss";

export default function ContentPreviewInfo({
    id = "",
    title = "Title",
    image = null,
    containerClassName = "",
    imageClassName = "",
    onClick = () => {},
}) {
    return (
        <div id={id} className={`content-preview-default ${containerClassName}`} onClick={onClick}>
            <img src={image} alt="" className={`content-preview-default__image ${imageClassName}`} />
            <Row className="content-preview-default__metadata-container" alignItems="center">
                <p className="content-preview-default__title">{title}</p>
            </Row>
        </div>
    );
}
