import React, { useEffect, useState } from "react";
import { CenteredColumn } from "../Flexbox/Column";
import Row from "../Flexbox/Row";
import CarouselCard from "./internal/CarouselCard";
import Arrows from "./assets/Arrow";
import Constants from "./CategoryCarousel.module.scss";
import "./CategoryCarousel.scss";

export default function CategoryCarousel({
    categoryTitle = "Playlist",
    categoryData = [],
    isResponsive = false,
    isPadded = true,
    isMobile = false,
    onCardClick = () => {},
    showActiveCard = false,
    showViewAll = false,
    onViewAll = () => {},
    isLoading = false,
    showArrows = !isMobile,
    // custom css
    carouselContainerClass = "",
    headerContainerClass = "",
    headerTitleClass = "",
    scrollerContainerClass = "",
    scrollerClass = "",
    cardContainerClass = "",
    cardClass = "",
    // other card props
    ...cardProps
}) {
    /*
        parse CARD_WIDTH from Constants scss file by default, OR use cusotm cardWidth prop if provided.
        cardWidth prop must be used with custom css classes for changes to be visible
    */
    const CARD_WIDTH = cardProps.cardWidth ?? parseInt(isMobile ? Constants.CARD_WIDTH_MOBILE : Constants.CARD_WIDTH);
    const CARD_OFFSET = cardProps.cardOffset ?? parseInt(isMobile ? Constants.CARD_OFFSET_MOBILE : Constants.CARD_OFFSET);
    const CARD_MARGIN = cardProps.cardMargin ?? parseInt(isMobile ? Constants.CARD_MARGIN_MOBILE : Constants.CARD_MARGIN);

    const [hasMoreLeft, setHasMoreLeft] = useState(false);
    const [hasMoreRight, setHasMoreRight] = useState(false);
    const [currIndex, setCurrIndex] = useState(0);
    const [numFullCards, setNumFullCards] = useState(0);
    const [activeCard, setActiveCard] = useState(null);

    useEffect(
        () => {
            getContainerSize();

            window.addEventListener("resize", getContainerSize);
            return () => window.removeEventListener("resize", getContainerSize);
        },
        // eslint-disable-next-line
        [isLoading],
    );

    const getScrollerContainer = () => document.getElementById(`category-carousel__carousel-scroller__${categoryTitle}`);

    const getContainerSize = () => {
        const scroller = getScrollerContainer();
        const contentWidth = scroller.scrollWidth;
        const containerWidth = scroller.clientWidth;

        /* Number of cards fully visible on the screen */
        const numCards = Math.floor((containerWidth + CARD_MARGIN - CARD_OFFSET) / (CARD_WIDTH + CARD_MARGIN));

        setNumFullCards(numCards);
        setHasMoreLeft(currIndex !== 0);
        setHasMoreRight(contentWidth > containerWidth && currIndex + numCards < categoryData.length);
    };

    const goLeft = () => {
        if (hasMoreLeft) {
            let index = currIndex - numFullCards;
            index = index < 0 ? 0 : index;
            const card = document.getElementById(`carousel-card__${categoryTitle}__${index}`);
            const scroller = getScrollerContainer();
            if (card && scroller) {
                scroller.scroll({ left: index === 0 ? 0 : card.offsetLeft, behavior: "smooth" });
            }
        }
    };

    const goRight = () => {
        if (hasMoreRight) {
            let index = currIndex + numFullCards;
            index = index > categoryData.length - 1 ? categoryData.length - 1 : index;
            const card = document.getElementById(`carousel-card__${categoryTitle}__${index}`);
            const scroller = getScrollerContainer();
            if (card && scroller) {
                scroller.scroll({ left: card.offsetLeft, behavior: "smooth" });
            }
        }
    };

    /* Enable showViewAll and provide onViewAll function */
    if (showViewAll) {
        categoryData = categoryData.concat({ type: "viewAll" });
    }

    return (
        <CenteredColumn className={`category-carousel ${carouselContainerClass}`}>
            <Row className={`category-carousel__header ${headerContainerClass}`} justifyContent="space-between">
                <p className={`category-carousel__title ${headerTitleClass}`}>{categoryTitle}</p>
                {showArrows && (
                    <Arrows
                        leftDisabled={!hasMoreLeft}
                        onLeftClick={goLeft}
                        rightDisabled={!hasMoreRight}
                        onRightClick={goRight}
                    />
                )}
            </Row>
            <Row
                className={`category-carousel__carousel-scroller-container${
                    isMobile ? "--mobile" : ""
                } ${scrollerContainerClass}`}
            >
                <Row
                    id={`category-carousel__carousel-scroller__${categoryTitle}`}
                    className={`category-carousel__carousel-scroller${
                        isLoading ? "--loading" : isMobile ? "--mobile" : ""
                    }  ${scrollerClass}`}
                    onScroll={(e) => {
                        if (isLoading) {
                            e.preventDefault();
                        } else {
                            const { scrollLeft, offsetWidth, scrollWidth } = e.target;
                            setHasMoreLeft(scrollLeft > 0);
                            setHasMoreRight(scrollLeft + offsetWidth < scrollWidth);

                            if (scrollLeft > 0) {
                                const scrollIndex = Math.floor(scrollLeft / CARD_WIDTH);
                                if (scrollIndex !== currIndex) {
                                    setCurrIndex(scrollIndex);
                                }
                            }
                        }
                    }}
                >
                    {isLoading
                        ? [...Array(numFullCards)].map((card, index) => <CarouselCard key={index} isLoading={true} />)
                        : categoryData.map((card, index) => {
                              const cardID = `carousel-card__${categoryTitle}__${index}`;

                              return (
                                  <CarouselCard
                                      key={index}
                                      cardID={cardID}
                                      card={card}
                                      isResponsive={isResponsive}
                                      onCardClick={() => {
                                          setActiveCard(cardID);
                                          onCardClick();
                                      }}
                                      onViewAll={onViewAll}
                                      active={showActiveCard && activeCard === cardID}
                                      cardContainerClass={cardContainerClass}
                                      cardClass={cardClass}
                                      {...cardProps}
                                  />
                              );
                          })}
                </Row>
            </Row>
        </CenteredColumn>
    );
}
