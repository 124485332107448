import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import "./App.scss";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
            </Routes>
        </>
    );
}

export default App;
