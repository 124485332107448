import React from "react";
import Column, { CenteredColumn } from "../components/Flexbox/Column";
import CategoryCarousel from "../components/CategoryCarousel";
import football from "../assets/football.svg";
import "../App.scss";

export default function HomeScreen() {
    const data = [
        {
            title: "Image 1",
            image: "https://i.picsum.photos/id/507/200/300.jpg?hmac=v0NKvUrOWTKZuZFmMlLN_7-RdRgeF-qFLeBGXpufxgg",
        },
        {
            title: "Image 2",
            image: "https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ",
        },
        {
            title: "Image 3",
            image: "https://i.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
        },
        {
            title: "Image 4",
            image: "https://i.picsum.photos/id/1003/1181/1772.jpg?hmac=oN9fHMXiqe9Zq2RM6XT-RVZkojgPnECWwyEF1RvvTZk",
        },
        {
            title: "Image 5",
            image: "https://i.picsum.photos/id/1025/4951/3301.jpg?hmac=_aGh5AtoOChip_iaMo8ZvvytfEojcgqbCH7dzaz-H8Y",
        },
        {
            title: "Image 6",
            image: "https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I",
        },
        {
            title: "Image 7",
            image: "https://i.picsum.photos/id/1036/4608/3072.jpg?hmac=Tn9CS_V7lFSMMgAI5k1M38Mdj-YEJR9dPJCyeXNpnZc",
        },
        {
            title: "Image 8",
            image: "https://i.picsum.photos/id/1011/5472/3648.jpg?hmac=Koo9845x2akkVzVFX3xxAc9BCkeGYA9VRVfLE4f0Zzk",
        },
    ];

    return (
        <Column className="App">
            <CenteredColumn className="App-header">
                <img src={football} className="App-logo" alt="logo" />
                <p>Avash Shrestha</p>
                <CategoryCarousel categoryTitle="Testing" categoryData={data} />
            </CenteredColumn>
        </Column>
    );
}
